<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./技术支持.jpg" alt="" />
    </div>
    <div class="bottomCss">
      <div class="title">Product type</div>
      <div class="xiangqing">
        <div class="neibiao">type</div>
        <el-checkbox-group
          style="margin-bottom: 20px"
          @change="getxilie"
          v-model="leixingData"
        >
          <el-checkbox
            v-for="item in leixingList"
            :key="item.id"
            :label="item.id"
            border
            >{{ item.title }}</el-checkbox
          >
        </el-checkbox-group>

        <!-- <div class="neibiao">系列</div>
        <el-checkbox-group v-model="xilieData">
          <el-checkbox
            v-for="item in xilieList"
            :key="item.id"
            :label="item.id"
            border
            >{{ item.title }}</el-checkbox
          >
        </el-checkbox-group> -->
      </div>

      <div class="title">File type</div>
      <div class="xiangqing">
        <el-checkbox-group style="margin-bottom: 20px" v-model="wenjianData">
          <el-checkbox
            v-for="item in wenjianList"
            :key="item.id"
            :label="item.id"
            border
            >{{ item.title }}</el-checkbox
          >
        </el-checkbox-group>
      </div>

      <div style="display: flex; width: 100%">
        <el-input
          style="margin-top: 20px; width: 200px"
          placeholder="Please enter"
          v-model="input"
          clearable
        >
        </el-input>
        <el-button
          @click="chaxun"
          style="margin-top: 20px; margin-left: 20px"
          type="primary"
          round
          >Submit</el-button
        >

        <div
          style="
            width: 110px;
            height: 44px;
            background: #f9fbfd;
            border-radius: 8px 8px 8px 8px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            margin-left: 20px;
          "
        >
        totality（{{ this.orderList.length }}）
        </div>
      </div>

      <el-table
        id="table"
        ref="tables"
        style="margin-top: 16px"
        :data="orderList"
      >
        <el-table-column
          label="filename"
          align="center"
          prop="fileName"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="Product type"
          align="center"
          prop="fileType"
          :show-overflow-tooltip="true"
        />
        <el-table-column label="" align="center">
          <template slot-scope="scope">
            <span
              style="
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #6780ff;
                cursor: pointer;
              "
              @click="Preview(scope.row)"
              >Preview</span
            >
            <span
              style="
                font-size: 18px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #ff6767;
                cursor: pointer;
                margin-left: 40px;
              "
              @click="Download(scope.row)"
              >Download</span
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { downloadFile } from "@/utils/download.js";
import { getProductTypes, getFileTypes, getFiles } from "@/api/index.js";
export default {
  data() {
    return {
      input: "",
      orderList: [],
      leixingList: [],
      leixingData: [],
      xilieList: [],
      xilieData: [],
      wenjianList: [],
      wenjianData: [],
    };
  },
  methods: {
    Download(item) {
      console.log(item);
      downloadFile(item.filePath, item.fileName);
    },
    Preview(item) {
      window.open(item.filePath, "_blank");
    },
    async chaxun() {
      let params = {
        fileName: this.input,
        productType: this.leixingData,
        serType: this.xilieData,
        fileType: this.wenjianData,
      };
      const res = await getFiles(params);
      this.orderList = res;
    },
    async getxilie(val) {
      console.log(this.leixingData);
      let params = {
        id: this.leixingData,
      };
      const res = await getProductTypes(params);
      this.xilieList = res;
      if (this.leixingData.length == 0) {
        this.xilieList = [];
      }
    },
    async getleixing() {
      let params = {
        id: "",
      };
      const res = await getProductTypes(params);
      this.leixingList = res;
    },
    async getwjlx() {
      const res = await getFileTypes();
      this.wenjianList = res;
    },
  },
  mounted() {
    this.getwjlx();
    this.getleixing();
    this.chaxun();
  },
};
</script>

<style scoped>
.el-table .odd-row {
  background: rgba(103, 144, 255, 0);
}

.el-table .even-row {
  background: rgba(103, 144, 255, 0.04);
}

.el-table {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  flex: 1;
  width: 1414px;
  max-width: 200%;
  font-size: 14px;
  color: #606266;
}
::v-deep .el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  background: rgba(103, 144, 255, 0.08);
}
::v-deep .el-table tr {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
}
.neibiao {
  font-size: 20px;
  line-height: 1.5;
  color: #666666;
  margin-bottom: 12px;
}
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 300px;
}
.xiangqing {
  width: 1414px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  padding: 24px 48px;
}
.title {
  font-size: 30px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-bottom: 24px;
  margin-top: 24px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
</style>